.input-range__slider {
  appearance: none;
  background: #B0C6E3;
  border: 1px solid #B0C6E3;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 20px;
  margin-left: -0.5rem;
  margin-top: -0.85rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 20px;
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #9d9d9d;
  border: 1px solid #9d9d9d;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #383739;
  font-size: 16px;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -0.8rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -2.3rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #9d9d9d;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #7389A5;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 300px;
  padding-top: 28px;
}

/*# sourceMappingURL=index.css.map */
